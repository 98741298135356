



























































































































































	import { Component, Vue } from 'vue-property-decorator';
	// import Util from '@/utils/Util';
	// import { Icon } from '@/model/util/Icon';
	import AppContainerBase from '@/components/util/AppContainerBase.vue';
	import AppDialog from '@/components/util/AppDialog.vue';
	// import { internet } from '@/utils/Internet';
	// import { IDataTable } from '@/model/main/IDataTable';
	// import Swal from 'sweetalert2';
	@Component({
		name: 'MainScripts',
		components: {
			AppContainerBase,
			AppDialog
		},
	})
	export default class MainScripts extends Vue {
        public title = "Creación de Script";
		public pantalla = 0;
		public script = "";
		public categorias:Array<any> = [];
		public nueva_cat = {
			'nombre': '',
			'ponderador': 0
		};
		public subcategorias:Array<any> = [];
		public nueva_sub = {
			'categoria': '',
			'subcategoria': '',
			'tipo': 'al menos uno',
			'elemento_explicito': [],
			'ponderador': 0
		};
		public dialog_finalizar = false;

		public atras() {
			this.pantalla -= 1;
		}

		public siguiente() {
			if (this.pantalla === 1) {
				this.categorias.forEach(categoria => {
					let nueva_sub = {
						categoria: categoria.nombre,
						subcategoria: '',
						tipo: '',
						elemento_explicito: '',
						ponderador: 0,
						elementos: []
					};
					this.subcategorias.push(nueva_sub);
				})
			}
			this.pantalla += 1;
		}

		public agregar_categoria() {
			let nueva_cat = {
				nombre: '',
				ponderador: 0
			};
			this.categorias.push(nueva_cat);
		}

		public eliminar_categoria(i:number) {
			console.log("Eliminando ", i);
		}

		public agregar_subcategoria(categoria:any) {
			let nueva_sub = {
				categoria: categoria.nombre,
				subcategoria: '',
				tipo: '',
				elemento_explicito: '',
				ponderador: 0,
				elementos: [],
			};
			this.subcategorias.push(nueva_sub);
		}

		public eliminar_subcategoria(i:number) {
			console.log("Eliminando sub ", i);
		}

		public finalizar() {
			this.dialog_finalizar = true;
		}

		public addElem(subcategoria:any) {
			subcategoria.elementos.push(subcategoria.elemento_explicito);
			subcategoria.elemento_explicito = "";
		}

		public deleteElem(subcategoria:any, elemento:string, index:any) {
			console.log(subcategoria, elemento);
			subcategoria.elementos.splice(index, 1);
		}

		public updateElem(subcategoria:any, elemento:string, index:any) {
			console.log(subcategoria, elemento);
			subcategoria.elementos.splice(index, 1);
			subcategoria.elemento_explicito = elemento;
		}
    }
